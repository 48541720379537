import * as React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  Announcement,
  Container,
  Row,
  Box,
  Section,
  Helmet,
  Banner,
  CardCentered,
  IconCare,
  IconChat,
  IconAdd,
  IconEvent,
  IconSchooling,
  IconEducation,
  Heading,
  PreStyledComponents,
  LinkList,
  routesObject
} from "@life-without-barriers/react-components"

import Layout from "../../../components/foster-care/LayoutCaring"
import { PageAnnouncement, Site } from "../../../contentTypes"
import {
  CarerGuide,
  CarerLearningPathways,
  CarerEvents,
  TheCaringDays,
  CarerBenefits,
  GetInTouch
} from "../../../components/foster-care/RelatedPagesContent"
import CarerTip, {
  CarerTipData
} from "../../../components/foster-care/CarerTip"

const { GridWrapper } = PreStyledComponents

interface IndexPageProps {
  location: { pathname: string }
  data: {
    announcement: PageAnnouncement
    bannerImage: IGatsbyImageData
    metaImage: IGatsbyImageData
    carerTips: { edges: [{ node: CarerTipData }] }
    site: Site
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "care") {
    return <IconCare color={color} height={height} />
  }
  if (type === "chat") {
    return <IconChat color={color} height={height} />
  }
  if (type === "add") {
    return <IconAdd color={color} height={height} />
  }
  if (type === "event") {
    return <IconEvent color={color} height={height} />
  }
  if (type === "schooling") {
    return <IconSchooling color={color} height={height} />
  }
  if (type === "education") {
    return <IconEducation color={color} height={height} />
  }
  return undefined
}

const CaringSpaceIndexPage = ({
  location,
  data: {
    bannerImage,
    carerTips,
    metaImage,
    announcement,
    site: { siteMetadata }
  }
}: IndexPageProps) => {
  const allCarerTips: CarerTipData[] = carerTips.edges.map(({ node }) => ({
    ...node
  }))

  return (
    <div>
      <Helmet
        title={`The Caring Space | ${siteMetadata.title}`}
        description="Whether you’re a new carer or you’ve been with us for years, the Caring Space is full of resources, training, and events to support you on your journey."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={metaImage}
      />
      <Layout siteMetadata={siteMetadata}>
        <Banner
          image={bannerImage}
          title="The Caring Space"
          subTitle="Whether you’re a new carer or you’ve been with us for years, the Caring Space is full of resources, training, and events to support you on your journey."
          breadcrumbs={[routesObject.fosterCareHome]}
        />
        <Section background="bg-hex-image">
          <Container>
            {announcement && (
              <Announcement insideBoxComponent={false} {...announcement} />
            )}
            <Row>
              <Box>
                <Heading size={2} className="tc color-lwb-theme-medium">
                  Find all the ways we support you here
                </Heading>
                <Row className="mt4 flex-wrap">
                  <CardCentered
                    {...CarerGuide}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    {...CarerLearningPathways}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    {...CarerEvents}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    {...TheCaringDays}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    {...CarerBenefits}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    {...GetInTouch}
                    iconHeight="67"
                    largeCopy
                    selectIcon={iconSelector}
                  />
                </Row>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Box>
            <Heading size={3} topMargin="none" sectionHeading className="tc-ns">
              More useful links
            </Heading>
            <GridWrapper>
              <LinkList
                links={[
                  {
                    label: "Carer allowance",
                    to: "/foster-care/support/carer-guide/topic/reimbursement/"
                  },
                  {
                    label: "Feedback and complaints",
                    to: "/contact-us/complaints-or-feedback/",
                    rooted: true
                  }
                ]}
              />
            </GridWrapper>
          </Box>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <div className="hide-in-percy">
              <CarerTip allCarerTips={allCarerTips} />
            </div>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    bannerImage: file(relativePath: { regex: "/caring-space-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1200)
      }
    }
    metaImage: file(relativePath: { regex: "/OG-image-fc.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
    carerTips: allContentfulCarerTip {
      totalCount
      edges {
        node {
          name
          tipType
          tipNumber
          tipText {
            raw
          }
          ctaUrl
          ctaLabel
          __typename
        }
      }
    }
    announcement: contentfulAnnouncement(page: { eq: "caring-space" }) {
      ...ContentfulAnnouncement
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default CaringSpaceIndexPage
