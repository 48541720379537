import { Footer, Theme } from "@life-without-barriers/react-components"
import React from "react"

const footerLinks = [
  {
    text: "Foster Care",
    url: "/foster-care/"
  },
  {
    text: "Caring Days",
    url: "https://www.facebook.com/groups/thecaringdays"
  }
]

const FooterCaring = () => (
  <Footer
    phoneNumber="1800 573 184"
    emailAddress="lwbcarers@lwb.org.au"
    links={footerLinks}
    theme={Theme.carerGuideThemeFull}
  />
)

export default FooterCaring
