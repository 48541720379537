import React, { useEffect, useState } from "react"
import { ThemeProps, withTheme } from "styled-components"
import {
  Box,
  Panel,
  Row,
  Interfaces,
  PreStyledComponents,
  Heading,
  IconOwl,
  IconLightBulb2,
  Theme,
  ReadMoreLink
} from "@life-without-barriers/react-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import sample from "lodash.sample"

const { IconWrapper } = PreStyledComponents
const { fosterCareThemeFull } = Theme

export type CarerTipType = "carer-lifehack" | "carer-wisdom"

export interface CarerTipData {
  tipType: CarerTipType
  tipNumber: number
  tipText: {
    raw: string
  }
  ctaUrl?: string
  ctaLabel?: string
}

interface CarerTipDisplay {
  heading: string
  icon: JSX.Element
  text: {
    raw: string
  }
  ctaUrl?: string
  ctaLabel?: string
}

const getRandomCarerTip = (
  carerTips: CarerTipData[]
): CarerTipDisplay | undefined => {
  if (!carerTips.length) {
    return undefined
  }
  const randomCarerTip = sample(carerTips)
  if (!randomCarerTip) {
    return undefined
  }

  const displayHeading =
    randomCarerTip.tipType === "carer-wisdom"
      ? `Carer Wisdom #${randomCarerTip.tipNumber}`
      : `Carer Lifehack #${randomCarerTip.tipNumber}`

  const icon =
    randomCarerTip.tipType === "carer-wisdom" ? (
      <IconOwl color={fosterCareThemeFull.dark} height="67" />
    ) : (
      <IconLightBulb2 color={fosterCareThemeFull.dark} height="67" />
    )

  return {
    icon,
    heading: displayHeading,
    text: randomCarerTip.tipText,
    ctaLabel: randomCarerTip.ctaLabel,
    ctaUrl: randomCarerTip.ctaUrl
  }
}

export interface CarerTipProps {
  allCarerTips: CarerTipData[]
}

export default withTheme(
  ({ allCarerTips, theme }: CarerTipProps & ThemeProps<Interfaces.Theme>) => {
    const [selectedCarerTip, setCarerTip] = useState<CarerTipDisplay>()

    useEffect(() => {
      const carerTip = getRandomCarerTip(allCarerTips)
      if (carerTip) {
        setCarerTip(carerTip)
      }
    }, [])

    return (
      <>
        {selectedCarerTip && (
          <Panel
            background="bg-lwb-white"
            padding="large"
            shadow={!!selectedCarerTip.ctaUrl}
          >
            <Row className="flex-wrap items-center-ns">
              <Box className="w-100 w-third-m w-25-l tc">
                <IconWrapper size="130" color={theme.xxxlight}>
                  {selectedCarerTip.icon}
                </IconWrapper>
              </Box>
              <Box className="w-100 w-two-thirds-ns mt3 mt0-ns">
                <Heading size={2} className="color-lwb-theme-medium tc tl-ns">
                  {selectedCarerTip.heading}
                </Heading>
                <div className="carer-tip-text tc tl-ns ph3 ph0-ns">
                  {renderRichText({
                    raw: selectedCarerTip.text.raw,
                    references: []
                  })}
                </div>
                {selectedCarerTip.ctaUrl && (
                  <div className="pt4 dib-ns tc">
                    <ReadMoreLink
                      href={selectedCarerTip.ctaUrl}
                      text={selectedCarerTip.ctaLabel}
                    />
                  </div>
                )}
              </Box>
            </Row>
          </Panel>
        )}
      </>
    )
  }
)
