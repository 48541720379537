import * as React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { Link, Variables } from "@life-without-barriers/react-components"
import { helpers } from "@life-without-barriers/utilities"

import { SiteMetadata } from "../../contentTypes"

import Logo from "./Logo/Logo"
import { LogoProps } from "./Logo/types"

const { noop } = helpers
const { youthPinkMedium, youthPinkDark } = Variables

export const NavWrapper = styled.nav`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  height: 77px;

  .isMobile {
    align-items: center;
    display: flex;
  }

  .isDesktop {
    display: none;
  }

  @media screen and (min-width: 56.25em) {
    .isMobile {
      display: none;
    }

    .isDesktop {
      align-items: center;
      display: flex;
    }
  }
`

export const CaringSpaceLink = styled(Link)`
  display: none;
  font-size: 30px;

  &:hover {
    color: ${youthPinkDark};
  }

  @media screen and (min-width: 40em) {
    display: block;
  }
`

interface Props {
  siteMetadata: SiteMetadata
}

interface State {
  showMobileSearchField: boolean
}

type LinkedLogoProps = {
  siteUrl: string
} & LogoProps

/**
 * LinkedLogo
 * A small link component wrapping the Logo SVG
 */
const LinkedLogo = (props: LinkedLogoProps) => (
  <Link
    className="flex pv3"
    tabIndex={0}
    to={`${props.siteUrl}/foster-care`}
    title="Life Without Barriers"
    onClick={() => {
      props.handler !== undefined ? props.handler() : noop()
    }}
  >
    <Logo type="logoheader" {...props} />
  </Link>
)

export default class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  public render() {
    const mobileNavClasses = classNames({
      "MenuBar relative z-5 w-100 pb0 ph3 ph4-ns bg-white flex": true
    })
    return (
      <header role="banner" id="header" className="bg-white fixed w-100 z-5">
        <NavWrapper
          id="mainNav"
          role="navigation"
          aria-label="Main Navigation"
          className={mobileNavClasses}
        >
          {/* DESKTOP NAVIGATION */}
          <div className="isDesktop w-100-ns flex items-center justify-between">
            <div className="flex items-center">
              <LinkedLogo
                height="25"
                width="333"
                color={youthPinkMedium}
                siteUrl={this.props.siteMetadata.siteUrl}
              />
              <CaringSpaceLink
                className="fw4 ml3 pv3 no-underline ttu lwb-color-pink"
                to="/foster-care"
              >
                The Caring Space
              </CaringSpaceLink>
            </div>
          </div>
          {/* MOBILE NAVIGATION */}
          <div className="isMobile w-100">
            <LinkedLogo
              height="16"
              width="215"
              color={youthPinkMedium}
              siteUrl={this.props.siteMetadata.siteUrl}
            />
          </div>
        </NavWrapper>
      </header>
    )
  }
}
