import * as React from "react"
import styled, { ThemeProvider } from "styled-components"
import "regenerator-runtime/runtime"
import { Theme, GlobalCSS } from "@life-without-barriers/react-components"

import { SiteMetadata } from "../../contentTypes"

import Footer from "./FooterCaring"
import Header from "./HeaderCaring"

export interface Props {
  children: React.ReactNode
  location?: Location
  siteMetadata: SiteMetadata
}

const StyledContent = styled.div`
  margin-top: 77px;
`
const { carerGuideThemeFull } = Theme

const Layout = (props: Props) => {
  return (
    <ThemeProvider theme={carerGuideThemeFull}>
      <div className="overflow-hidden">
        <GlobalCSS />
        <Header siteMetadata={props.siteMetadata} />
        <StyledContent role="main">{props.children}</StyledContent>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout
