import * as React from "react"

import LogoFooter from "./LogoFooter"
import LogoHeader from "./LogoHeader"
import { LogoProps } from "./types"

const Logo = (props: LogoProps) => {
  const { type } = props
  if (type === "logofooter") {
    return <LogoFooter {...props} />
  }
  return <LogoHeader {...props} />
}

export default Logo
